
.IconAI{
    position: fixed;
    bottom: 15px;
    right: -5px;
    background: #5569ff;
    z-index: 10;
    transform: 1s;
    width: 40px;
    transition: width 0.3s, border-radius 0.3s;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
}

.IconAI:hover {
    color: #162138;
    width: 100px;
    transition: width 0.3s ease-in-out;
    border-radius: 20px;
    background: #5569ff;
    animation: shadow-pulse 1.5s infinite;
}

.IconAI h5{
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
}

.IconAI:hover h5{
    width: 30px;
    transition: width 0.3s ease-in-out;
    display: inherit;
}

.ButtonAI{
    position: fixed;
    bottom: 0px;
    right: 0px;
    height: 500px;
    width: 400px;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 1280;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: translateY(100%);
    /* transition: 0.5s; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;   
}

.ChatAI{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 500px;
    width: 400px;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 1280;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* transform: translateY(100%); */
    /* transition: 0.5s;  */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;   
}

@supports (-moz-appearance:none) {
    .ChatAI { right: 8px; } 
}

.openChat{
    transform: translateY(0%);
}

.headerChat{
    background: linear-gradient(45deg, #5569ff, transparent);
    color: #fff;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.headerChat:hover{
    cursor: grab;
}

.chatResizeContainer{
    width: 100%;
    height: 21px;
    background-color: #5569ff;
}

.chatResize{
    position: absolute;
    bottom: 4px;
    right: 2px;
    width: 21px;
    height: 21px;
    background: transparent;
    z-index: 1;
}

.chatResize:hover{
    cursor:all-scroll;
}

.chatResize svg{
    color: rgb(207, 207, 207);
}

.chatResize:hover svg{
    color: #fff!important;
}

.IconSiria{
    width: 0.7em;
    height: 0.7em;
    margin-right: 5px;
}

.TextSiria{
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: 2px;
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 112, 244, 0.4);
  }
  100% {
    box-shadow: 0 0 7px 25px rgba(0, 112, 244, 0);
  }
}
.table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 5px; 
  border-spacing: 0px;
  font-size: 14px;
  min-width: 700px;
}

.table tbody tr{
  animation: fadeIn 0.6s;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: center;
}

.table thead {
  background-color: #f5f5f5;
  color: #162138;
}

.table tr:nth-of-type(even) {
  background-color: #fbfbfb;
}

tbody tr:hover{
  background-color: #ececec!important;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
